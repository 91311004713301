import * as jQuery from 'jquery';
import { Component, OnInit, HostListener } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { APPCONFIG } from './config';
import { LayoutService } from './layout/layout.service';
import { filter } from 'rxjs/operators';

// 3rd
import 'styles/material2-theme.scss';
import 'styles/bootstrap.scss';
// custom
import 'styles/layout.scss';
import 'styles/theme.scss';
import 'styles/ui.scss';
import 'styles/app.scss';

declare var gtag: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: [
    `
      .scroll-to-top {
        position: fixed;
        bottom: 20px;
        right: 20px;
        padding: 10px;
        border: none;
        border-radius: 5%;
        background-color: #007bff;
        color: white;
        font-size: 18px;
        cursor: pointer;
        z-index: 1000;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
      }

      .scroll-to-top:hover {
        background-color: #0056b3;
      }
    `],
  providers: [LayoutService],
})

export class AppComponent implements OnInit {
  public AppConfig: any;
  constructor(private router: Router) {

    // const navEndEvents = this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // );
    // navEndEvents.subscribe((event: NavigationEnd) => {
    //   gtag('config', 'UA-146241883-1', {
    //     'page_path': event.urlAfterRedirects
    //   });
    // });
  }

  isVisible = false;

  // Show the button when scrolling down
  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.isVisible = window.pageYOffset > 300;
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  ngOnInit() {
    this.AppConfig = APPCONFIG;

    // Scroll to top on route change
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      if (evt instanceof NavigationEnd) {
        if (!(evt.urlAfterRedirects.endsWith('list')))
          window.scrollTo(0, 0);
      }
      // window.scrollTo(0, 0);

    });
  }
}
